import httpClient from './httpClient'

const apiGetChannelList = () => httpClient.get('https://admin.jacoblima.com/channels'),
	apiGetChannel = (val) => httpClient.get('https://admin.jacoblima.com/channel/' + val.id + '?region=' + val.region),
	apiModUrl = (val) => httpClient.put('https://admin.jacoblima.com/destination/', val),
	apiCyclePower = (val) => httpClient.put('https://admin.jacoblima.com/powercycle/', val)

export {
	apiGetChannelList,
	apiGetChannel,
	apiModUrl,
	apiCyclePower
}