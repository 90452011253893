import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/ml/:channel*',
    name: 'Route Handler',
    component: () => import(/* webpackChunkName: "channelpage" */ '@/views/Channel.vue'),
    meta: { login: false, roles: [], title: 'RouteHandler' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "loginpage" */ '@/components/Login.vue'),
    meta: { login: false, roles: [], title: 'Login' }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { login: false, roles: [], title: 'Logout' },
    beforeEnter(to, from, next) {
      store.dispatch('Wolf/processLogout', null, { root: true })
      next('/login')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('Wolf/checkApiAuth', null, { root: true })
    .then(() => {
      if (store.getters['Wolf/getLoggedIn']) {
        store.dispatch('Wolf/fetchUser', null, { root: true })
          .then(() => {
            if (to.meta.roles) {
              if (to.meta.roles.length) {
                if (to.meta.roles.indexOf(store.getters['Wolf/getRole']) !== -1) {
                  next()
                } else {
                  /* --- we expect this to be after login --- */
                  const gohere = localStorage.getItem('entrypath')

                  if (gohere) {
                    localStorage.removeItem('entrypath')
                    router.push(gohere)
                  } else {
                    next(process.env.VUE_APP_START)
                  }
                }
              } else {
                next()
              }
            } else {
              next()
            }
          })
      } else {
        if (to.path !== '/login') {
          router.push('/login')
        } else {
          next()
        }
      }
    })
})

router.afterEach(() => {
  let titleval = ''

  if (router.currentRoute.params.channel) {
    if (Array.isArray(router.currentRoute.params.channel)) {
      store.dispatch("Channel/fetchChannel", { region: router.currentRoute.params.channel[0], id: router.currentRoute.params.channel[1] }, { root: true })
        .then(() => {
          titleval = store.getters["Channel/getChannel"].Name.toUpperCase()

          store.commit('Wolf/SET_COMPONENT', 'channelinfo')
          store.commit('Wolf/SET_PAGENAME', titleval)
          document.title = titleval + " - JL" || "JL";
        })
        .catch(() => {
        })
    } else {
      /* --- double check array --- */
      if (router.currentRoute.params.channel.indexOf('/') === -1) {
        titleval = router.currentRoute.params.channel.toUpperCase()

        store.commit('Wolf/SET_COMPONENT', 'regionlist')
        store.commit('Wolf/SET_PAGENAME', titleval)
        document.title = titleval + " - JL" || "JL";
      } else {
        /* --- we expect an array size of 2 --- */
        /* --- any channel param with a slash is a single channel --- */
        const splitit = router.currentRoute.params.channel.split('/')

        store.dispatch("Channel/fetchChannel", { region: splitit[0], id: splitit[1] }, { root: true })
          .then(() => {
            titleval = store.getters["Channel/getChannel"].Name.toUpperCase()

            store.commit('Wolf/SET_COMPONENT', 'channelinfo')
            store.commit('Wolf/SET_PAGENAME', store.getters["Channel/getChannel"].Name.toUpperCase())
            document.title = titleval + " - JL" || "JL";

          })
          .catch(() => {
          })

      }
    }
  }

})

export default router