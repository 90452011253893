<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Navigation />
    <v-footer app>
      <span>&copy; {{ jlyear }} JL </span>
      <v-flex class="pl-4" style="opacity: 0.5; font-size: 0.75rem">
        version: {{ jlver }}
      </v-flex>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import moment from "moment";

export default {
  components: {
    Navigation,
  },
  data: () => ({
    jlyear: moment().format("YYYY"),
    jlver: process.env.VUE_APP_VERSION,
  }),
};
</script>