/* --- intended for app-wide use --- */
import router from '@/router'
import cookie from 'vue-cookies'
import jwt_decode from "jwt-decode"
import { apiAuthenticate, apiGetUser } from '@/api/wolf.api'

const initialState = () => ({
	jwt: '',
	pagename: '',
	msgtimeout: 5000,
	loggedin: false,
	failLogin: false,
	loginNotice: 'Either the username or password are incorrect, please try again.',
	user: {
		username: '',
		role: { type: '' },
		loggedin: false
	},
	usernav: [
		{ title: 'Logout', gohere: '/logout' }
	],
	refreshing: false,
	rolemenu: [],
	gohere: '',
	loadcomponent: ''
})

const state = initialState()

const actions = {
	reset({ commit }) {
		commit('RESET')
	},
	async checkApiAuth({ commit, dispatch }) {
		if (cookie.isKey('wjaw')) {
			const jwtc = cookie.get('wjaw')
			const jwt = jwt_decode(jwtc)

			if (jwt.exp > Date.now()) {
				/* --- reauth --- */
				commit('SET_LOGGEDIN', false)
			} else {
				commit('SET_LOGGEDIN', true)

				dispatch('fetchUser')
			}

		} else {
			commit('SET_LOGGEDIN', false)
		}
	},
	async processLogout({ commit }) {
		cookie.remove('wjaw')
		commit('SET_LOGGEDIN', false)
		commit('SET_USER', {})
	},
	async processLogin({ commit }, who) {
		await apiAuthenticate({ op: 'login', identifier: who.u, password: who.p })
			.then(response => {
				if (response.status === 200) {
					cookie.set('wjaw', response.data.jwt)

					commit('SET_FAILLOGIN', false)
					commit('SET_LOGGEDIN', true)

					router.replace(process.env.VUE_APP_START)
				} else {
					switch (response.data.message) {
						case 'Too many requests':
							commit('SET_LOGINNOTICE', 'Too many attempts, contact an administrator.')
							commit('SET_FAILLOGIN', true)
							break
						case 'Not logged in, log out irrelevant':
							/* ---- stupid recursion, ignore --- */
							break
						case 'auth NOT set':
							/* ---- returned from check op, no need to logout or change notice --- */
							//router.push({path: '/login'})
							break
						default:
							/* --- failed for some other reason --- */
							//dispatch('processLogout')
							commit('SET_LOGINNOTICE', 'Either the username or password are incorrect, please try again.')
							commit('SET_FAILLOGIN', true)
					}
				}

			})
			.catch(function (err) {
				commit('SET_LOGINNOTICE', 'Server thinks username or password are incorrect, please try again.')
				commit('SET_FAILLOGIN', true)

				console.log('PROCESSLOGIN ERROR HERE')
				console.log(err)
			})
	},
	async fetchUser({ commit }) {
		await apiGetUser()
			.then(response => {
				commit('SET_USER', response)
			})
			.catch(function (err) {
				console.log('FETCHUSER ERROR HERE')
				console.log(err)
			})
	},
}

const mutations = {
	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	SET_MSGTIMEOUT(state, val) {
		state.msgtimeout = val
	},
	SET_LOGGEDIN(state, val) {
		if (state.user) {
			state.user.loggedin = val
		}

		state.loggedin = val
	},
	SET_FAILLOGIN(state, val) {
		state.failLogin = val
	},
	SET_USER(state, val) {
		state.user = val.data
	},
	SET_LOGINNOTICE(state, val) {
		state.loginNotice = val
	},
	SET_REFRESHING(state, val) {
		state.refreshing = val
	},
	SET_PAGENAME(state, val) {
		state.pagename = val
	},
	SET_COMPONENT(state, val) {
		state.loadcomponent = val
	}
}

const getters = {
	getMsgTimeout(state) {
		return state.noimgmsg
	},
	getLoggedIn(state) {
		return state.loggedin
	},
	getFailLogin(state) {
		return state.failLogin
	},
	getLoginNotice(state) {
		return state.loginNotice
	},
	getUser(state) {
		return state.user
	},
	getRole(state) {
		return state.user.role.name
	},
	getUserNav(state) {
		return state.usernav
	},
	getRefreshing(state) {
		return state.refreshing
	},
	getRoleMenu(state) {
		return state.rolemenu
	},
	getPageName(state) {
		return state.pagename
	},
	getComponent(state) {
		return state.loadcomponent
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}