import axios from 'axios'
import cookie from 'vue-cookies'

const httpClient = axios.create({
	timeout: 5000,
	rejectUnauthorized: false
})

const getAuthToken = () => cookie.get('wjaw')

const authInterceptor = (config) => {
	let sendbearer = true

	if (config.data) {
		if (config.data.op === 'login') {
			/* --- logging in, don't set bearer token --- */
			sendbearer = false
		}
	}

	if (sendbearer) {
		config.headers['Authorization'] = 'Bearer ' + getAuthToken()
	}

	return config
}

httpClient.interceptors.request.use(authInterceptor)

export default httpClient