import {
	apiGetChannelList,
	apiGetChannel,
	apiModUrl,
	apiCyclePower
} from '@/api/channel.api'

import router from '@/router'

const initialState = () => ({
	title: 'Order List',
	vendlist: [],
	channellist: [],
	channelmenu: [],
	channel: {},
	powercolor: ''
})

const state = initialState()

const actions = {
	reset({ commit }) {
		commit('RESET')
	},
	async fetchChannelList({ commit }) {
		commit('Wolf/SET_REFRESHING', true, { root: true })

		await apiGetChannelList()
			.then(response => {

				commit('SET_CHANNELLIST', response.data)

				commit('Wolf/SET_REFRESHING', false, { root: true })
			})
			.catch(function (err) {
				console.log('GET CHANNEL LIST ERROR HERE')
				console.log(err)
			})
	},
	async fetchChannel({ commit }, val) {
		await apiGetChannel(val)
			.then(response => {
				//console.log('CHANNEL INFO')
				//console.log(response.data)
				commit('SET_CHANNEL', response.data)
			})
			.catch(function (err) {
				console.log('GET CHANNEL ERROR HERE')
				console.log(err)
			})
	},
	async changeUrl({ commit }, val) {
		commit('Wolf/SET_REFRESHING', true, { root: true })
		await apiModUrl(val)
			.then(() => {
				commit('Wolf/SET_REFRESHING', false, { root: true })
			})
			.catch(function (err) {
				console.log('MOD URL ERROR HERE')
				console.log(err)
			})
	},
	async cyclePower({ commit }, val) {
		commit('Wolf/SET_REFRESHING', true, { root: true })
		await apiCyclePower(val)
			.then((ret) => {
				console.log('CYCLING POWER')
				console.log(ret)

				commit('Wolf/SET_REFRESHING', false, { root: true })
			})
			.catch(function (err) {
				console.log('MOD URL ERROR HERE')
				console.log(err)
			})
	}
}

const mutations = {
	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	SET_CHANNEL(state, vals) {
		let arn = vals.Arn

		arn = arn.split(':')

		vals['region'] = arn[3]

		switch (vals.State) {
			case 'IDLE':
				state.powercolor = 'red'
				break
			case 'RUNNING':
				state.powercolor = 'green'
				break
			case 'STARTING':
				state.powercolor = 'lightgreen'
				break
			case 'STOPPING':
				state.powercolor = 'lightred'
				break
			default:
				state.powercolor = 'purple'
		}

		state.channel = vals
	},
	SET_CHANNELLIST(state, vals) {
		let region = '',
			haveactive = 'IDLE'

		//console.log('ROUTE')
		//console.log(router.currentRoute.params.channel)

		state.channellist = []
		state.channelmenu = []

		for (let idx = 0; idx < vals.length; idx++) {
			haveactive = 'IDLE'
			region = vals[idx].region

			if (
				router.currentRoute.params.channel === region ||
				router.currentRoute.params.channel === 'all'
			) {
				for (let lidx = 0; lidx < vals[idx].vals.length; lidx++) {
					state.channellist.push({
						id: vals[idx].vals[lidx].id,
						region: region,
						name: vals[idx].vals[lidx].name,
						gohere: '/channel/' + vals[idx].vals[lidx].id,
						state: vals[idx].vals[lidx].state
					})

					if (vals[idx].vals[lidx].state !== 'IDLE') {
						haveactive = vals[idx].vals[lidx].state
					}
				}
			}

			state.channelmenu.push({
				icon: 'mdi-web',
				region: region,
				channels: vals[idx].vals,
				state: haveactive
			})
		}
	},
	SET_VENDLIST(state, vals) {
		state.vendlist = vals
	},
}

const getters = {
	getChannelList(state) {
		return state.channellist
	},
	getChannelMenu(state) {
		return state.channelmenu
	},
	getChannel(state) {
		return state.channel
	},
	getPowerColor(state) {
		return state.powercolor
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}