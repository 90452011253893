<template>
  <div class="d-print-none">
    <v-card>
      <v-navigation-drawer permanent expand-on-hover clipped app>
        <v-list nav dense>
          <v-list-item-group v-if="loggedin">
            <v-list-item to="/ml/all">
              <v-list-item-icon>
                <v-icon>mdi-web</v-icon>
              </v-list-item-icon>
              <v-list-item-title>All</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(menu, x) in channelmenu"
              :key="x"
              :to="{ path: '/ml/' + menu.region }"
              :class="'wolf-menu ' + menu.state"
            >
              <v-list-item-icon>
                <v-icon v-html="menu.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="menu.region"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <v-app-bar fixed app clipped-left extension-height="8">
      <v-flex xs1>
        <router-link :to="homelink">
          <v-img
            style="width: 40%; height: auto"
            src="https://wolf.jacoblima.com/images/wolf.png"
          ></v-img>
        </router-link>
      </v-flex>
      <v-flex xs1>&nbsp;</v-flex>
      <v-toolbar-title
        v-if="!loggedin"
        class="mt-5"
        v-text="'Log In'"
      ></v-toolbar-title>
      <v-toolbar-title
        v-if="loggedin"
        class="mt-5"
        v-text="title + ' - ' + pagename"
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-flex xs2 class="mt-5" v-if="loggedin">
        <v-menu open-on-hover top offset-y>
          <template v-slot:activator="{ on }">
            <v-flex v-on="on">
              <v-icon color="indigo">mdi-account-circle-outline</v-icon>
              {{ user.username }}
            </v-flex>
          </template>
          <v-list dense>
            <v-list-item
              style="cursor: pointer"
              v-for="(unav, ni) in usernav"
              :key="ni"
              :to="{ path: unav.gohere }"
            >
              <v-list-item-title>{{ unav.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
      <v-row slot="extension">
        <v-flex xs12>
          <v-progress-linear
            dense
            v-if="refreshing"
            transition="scroll-y-transition"
            indeterminate
            color="grey lighten-1"
            background-color="transparent"
            background-opacity="0.3"
            stream
          ></v-progress-linear>
        </v-flex>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  computed: {
    homelink: {
      get() {
        return process.env.VUE_APP_START;
      },
    },
    loggedin: {
      get() {
        return this.$store.getters["Wolf/getLoggedIn"];
      },
    },
    user: {
      get() {
        return this.$store.getters["Wolf/getUser"];
      },
    },
    usernav: {
      get() {
        return this.$store.getters["Wolf/getUserNav"];
      },
    },
    pagename: {
      get() {
        return this.$store.getters["Wolf/getPageName"];
      },
    },
    refreshing: {
      get() {
        return this.$store.getters["Wolf/getRefreshing"];
      },
    },
    channelmenu: {
      get() {
        return this.$store.getters["Channel/getChannelMenu"];
      },
    },
  },
  data: () => ({
    title: "Channel Operations",
  }),
  mounted() {},
  methods: {
    checkme(priv) {
      if (this.$store.dispatch("checkprivs", [priv])) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #eeeeee;
}

.centerit {
  margin-top: 4rem;
  padding-top: 1rem;
}

.centerit div {
  text-align: center;
}

.rightside {
  margin-left: auto;
}

.v-list {
  padding: 0;
}

.wolf-menu.RUNNING {
  background-color: rgb(220, 241, 220);
}
</style>